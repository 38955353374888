<template>
    <div class="right">
        <span class="spen">我的会员</span>
        <div class="menu">

        </div>
        <Loading v-if="isLoading" />
        <div v-else>
            <div class="tishi" v-if="member_list == ''">{{ tishi }}</div>
            <div class="top" v-else>
                <el-table :data="member_list" style="width: 100%">
                    <el-table-column prop="username" label="会员账户"></el-table-column>
                    <el-table-column prop="companyName" label="所属公司"></el-table-column>
                    <el-table-column prop="phone" label="联系方式"> </el-table-column>
                    <el-table-column prop="email" label="会员邮箱"> </el-table-column>
                </el-table>
            </div>
            <div class="fenye" v-if="member_list != ''">
                <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="totalNum"
                        @current-change="pagechange"
                        :current-page.sync="currentPage"
                        v-if="totalNum != 0"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import { post, get } from '@/utils/request';
    import { setContextData, getContextData } from '../../../utils/session.js';
    export default {
        data() {
            return {
                member_list: [],
                isLoading: true,
                currentPage: 1,
                totalNum: 1,
                mes: '',
                tishi: '空空如也~',
            };
        },
        created() {
            this.currentPage = getContextData('currentPage_hy') || 1;
            this.getMember(this.currentPage);
        },
        methods: {
            pagechange(p) {
                this.isLoading = true;
                this.currentPage = p;
                setContextData('currentPage_hy', this.currentPage);
                get(
                    'api/managerMember/getMemberByManagerId?pageNum=' + p + '&pageSize=10'
                ).then((res) => {
                    this.isLoading = false;
                    this.member_list = res.data.data.list;
                });
            },
            getMember(e) {
                get('api/managerMember/getMemberByManagerId?pageNum=' + e + '&pageSize=10').then(
                    (res) => {
                        this.isLoading = false;
                        this.member_list = res.data.data.list;
                        this.totalNum = res.data.data.total;
                    }
                );
            },
        },
    };
</script>
<style lang="scss" scoped>
    .tishi {
        width: 90%;
        text-align: center;
        margin-top: 150px;
        font-size: 24px;
        color: rgb(139, 135, 135);
    }
    .fenye {
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .right {
        width: 1006px;
        padding-top: 34px;
        padding-left: 27px;
        background: #fff;
        .spen {
            font-size: 26px;
            font-weight: 600;
            color: #616161;
        }
        .menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // .qiehuan {
            //   height: 80px;
            //   display: flex;
            //   align-items: center;
            //   cursor: default;
            //   .daohang {
            //     font-size: 15px;
            //     color: #272727;
            //     margin-right: 25px;
            //     cursor: pointer;
            //   }
            //   .bian {
            //     color: #1a4fff;
            //   }
            // }
            .daohang {
                font-size: 15px;
                color: #272727;
                margin-right: 25px;
                height: 30px;

                .ul {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0;
                    width: 250px;
                    height: 32px;
                    line-height: 40px;
                    li {
                        list-style: none;
                        cursor: pointer;
                        &.router-link-exact-active.router-link-active {
                            color: #3661fe;
                        }
                    }
                }
            }
            // .search {
            //   margin-right: 150px;
            //   display: flex;
            //   input {
            //     border: 0;
            //     outline: none;
            //     border: 1px solid #a7a7a7;
            //     height: 37px;
            //     padding-left: 10px;
            //   }
            //   .fangdajing {
            //     width: 45px;
            //     height: 40px;
            //     border: 1px solid #a7a7a7;
            //     border-left: none;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //   }
            // }
        }
        .top {
            margin-top: 30px;
        }
    }
</style>
